<template>
  <div class="row mx-0 h-100">
    <div class="col-12 p-0 background_image h-100 border_radius">
      <div class="row mx-0 h-100 justify-content-center background_overlay border_radius">
        <div class="col-12 p-4 card_item h-100">
          <div class="row mx-0 h-100 justify-content-center" :class="[disabled]">
            <div class="col-12 p-0">
              <div class="row mx-0 pt-3 justify-content-center holder" v-if="bgimage || icon ||  title || heading || greenheading">
                <div class="col-12 p-0 above">
                  <div class="row m-0 justify-content-center">
                    <div class="col-auto text-center p-0 mb-3" v-if="icon">
                      <IconRound size="large" align="center" >
                        <slot />
                      </IconRound>
                    </div>
                    <div class="col-12 text-center p-0 mb-3" v-if="image">
                      <slot />
                    </div>
                    <div class="col-12 text-center p-0 mb-3" v-if="title">
                      <div class="card_title">
                        {{title}}
                      </div>
                    </div>
                    <div class="col-12 text-center p-0 mb-3" v-if="heading">
                      <div class="card_heading">
                        {{heading}}
                      </div>
                    </div>
                    <div class="col-12 text-center p-0" v-if="greenheading">
                      <div class="card_heading_green">
                        {{greenheading}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-11 text-center" v-if="content" v-html="content">
            </div>
            <div class="col-12 text-center green-text align-self-end my-3 medium bigger_font" v-html="footer" v-if="footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('../components/IconRound.vue'))
  },
  name: 'Card',
  props: ['icon', 'title', 'heading', 'content', 'footer', 'bgimage', 'image', 'greenheading', 'disabled']
}
</script>

<style scoped>
.card_item {
  /* background-color: #fff; */
  border-radius: 13px;
  /* box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%); */
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.09);
  color: var(--normal-color);
}
.disabled {
  opacity: 0.5;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.card_heading_green {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--green-color);
}
.holder {
  position: relative;
}
.above {
  z-index: 1;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.98) 0%, rgb(255, 255, 255) 100%);
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-size: 50%;
  opacity: 0.02;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.background_image {
  background-color: #fff;
  background-image: url(../assets/images/bg.png);
  background-position: 50% 50%;
  background-size: 100%;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.background_overlay {
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFFFA 0%, #FFFFFF 100%);
  border-radius: 13px;
  opacity: 1;
}
.border_radius {
  border-radius: 13px;
}

@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
    color: var(--green-color);
  }
  .bigger_font {
    font-size: 1.2rem;
  }
}
</style>
